<template>
  <div>
    <div class="title">仓单过户单管理</div>

    <div>
      <div class="c-title">过户信息</div>
      <div>
        <el-form ref="form" :model="infoForm" size="small">
          <el-row>
            <el-col :span="6">
              <el-form-item label="卖方：" label-width="100px" prop="sellerName">
                <el-input v-model="infoForm.sellerName" style="width: 220px;" disabled></el-input>
              </el-form-item>
              <el-form-item label="交货仓库：" label-width="100px" prop="warehouseName">
                <el-select v-model="infoForm.warehouseName" placeholder="请选择" style="width: 220px;">
                  <el-option v-for="item in options" :key="item.warehouseId" :label="item.warehouseName" :value="item.warehouseId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="买方：" label-width="100px" prop="buyerName">
                <el-input v-model="infoForm.buyerName" style="width: 220px;" disabled></el-input>
              </el-form-item>
              <el-form-item label="关联合同：" label-width="100px" prop="contractNumber">
                <el-input v-model="infoForm.contractNumber" style="width: 220px;" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>

    <div>
      <div class="c-title">货物信息</div>
      <div class="bill-btn" @click="chooseBill">
        选择仓单
      </div>
      <div>
        <el-table :data="tableData" :header-cell-style="{'text-align':'center','font-weight': 'normal','font-size': '12px', 'color': '#333'}" :cell-style="{'text-align':'center','font-weight': 'normal','font-size': '12px','color': '#333'}" border style="width: 100%">
          <el-table-column prop="commodityName" label="品名" min-width="150">
          </el-table-column>
          <el-table-column prop="warehouseReceiptNumber" label="仓单编号" min-width="300">
          </el-table-column>
          <el-table-column prop="warehouseName" label="仓库名称" min-width="280">
          </el-table-column>
          <el-table-column prop="availableWeightTon" label="过户量(吨)" width="150">
          </el-table-column>
          <el-table-column prop="location" label="库位" width="260">
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button @click="delItem(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="form-container">
        <el-form ref="form" :model="form" :rules="rules" label-width="180px" size="small">
          <el-form-item label="短信验证码：" prop="mobilePhoneVerificationCode">
            <div style="display:flex;align-items: center;">
              <div style="width: 200px;margin-right:10px;">
                <el-input  v-model.trim="form.mobilePhoneVerificationCode"></el-input>
                <el-input  style="position:fixed;bottom:-99999px"></el-input>
              </div>
              <div class="code-btn" @click="sendCode" :class="{ disabled: !show }">
                {{ show ? "发送验证码" : `${timeCount}s` }}
              </div>
            </div>
          </el-form-item>
          <el-form-item label="交易密码：" prop="transactionPassword">
            <div style="display:flex;align-items: center;">
              <div style="width: 200px;margin-right:10px;">
                <el-input type="password" auto-complete="new-password" v-model.trim="form.transactionPassword" maxlength="6"></el-input>
              </div>
              <div class="pwd-txt" v-show="cPaths.indexOf('/userInfo/transactionPwd') > -1" @click="setToPwd">设置交易密码</div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="submit-btn" @click="onSubmit">提交过户</div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-dialog title="选择过户仓单" :visible.sync="dialogTableVisible" :close-on-click-modal="false">
      <el-table ref="multipleTable" height="480" :header-cell-style="{'text-align':'center','font-weight': 'normal','font-size': '12px', 'color': '#333'}" :cell-style="{'text-align':'center','font-weight': 'normal','font-size': '12px','color': '#333'}" :data="gridTableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="commodityName" label="品名" width="120">
        </el-table-column>
        <el-table-column prop="warehouseReceiptNumber" label="仓单编号" width="200">
        </el-table-column>
        <el-table-column prop="availableWeightTon" label="仓单可用量(吨)">
        </el-table-column>
        <el-table-column prop="warehouseName" label="仓库">
        </el-table-column>
        <el-table-column prop="location" label="库位">
        </el-table-column>
      </el-table>
      <p style="text-align:right">已选{{multipleSelection.length}}条 合计: {{getWeightTonTotel}} (吨)</p>
      <div class="btn-group">
        <div class="btn confirm" @click="confirm">确 认</div>
        <div class="btn cancel" @click="cancel">取 消</div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import axios from 'axios'
import utils from '@/utils'
const http = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json'
  }
})
export default {
  data () {
    return {
      getWeightTonTotel: 0,
      warehouseName: '',
      options: [],
      tableData: [],
      dialogTableVisible: false,
      gridTableData: [],
      infoForm: {
        sellerName: '',
        buyerName: '',
        warehouseName: '',
        contractNumber: ''
      },
      form: {
        mobilePhoneVerificationCode: '',
        transactionPassword: ''
      },
      rules: {
        transactionPassword: {
          pattern: /\d{6}$/,
          message: '交易密码格式不正确',
          trigger: 'blur'
        }
      },
      show: true,
      timeCount: 60,
      timer: null,

      multipleSelection: [],
      cPaths: []
    }
  },
  props: {
    contractId: {
      type: String,
      default () {
        return ''
      }
    },
    sellerName: {
      type: String,
      default () {
        return ''
      }
    },
    buyerName: {
      type: String,
      default () {
        return ''
      }
    },
    contractNumber: {
      type: String,
      default () {
        return ''
      }
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.UserInfo
    }
  },
  created () {
    // 获取交货仓库
    this.getWarehouseList()

    let menu = this.$store.state.menu
    let userMenu = menu.filter(item => {
      return item.path === '/userInfo'
    })
    let cUserPaths = userMenu[0]['children']
    cUserPaths.forEach(item => {
      this.cPaths.push(item.path)
    })
  },
  mounted () {
    this.infoForm.sellerName = this.sellerName
    this.infoForm.buyerName = this.buyerName
    this.infoForm.contractNumber = this.contractNumber
  },
  methods: {
    getWarehouseList () {
      let params = {
        process_id: '00000208',
        nextProcessNode: '004',
        addr: 'listWarehousesForCurrentOrganization',
        nodeInfo: {
          wmsId: 'yuncang', // 目前写死为“yuncang”
          size: -1, // 分页页容量（从0开始，-1代表查全部）
          page: 0, // 分页页码（从0开始）
          contractId: this.contractId
        }
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params).then((res) => {
        this.options = res.data.content
      }).catch((err) => {
        console.log(err)
      })
    },
    chooseBill () {
      if (!this.infoForm.warehouseName) {
        this.$alert('请先选择交收仓库')
        return
      }
      this.dialogTableVisible = true
      // 过户仓单列表
      this.getBillList()
    },
    getBillList () {
      let warehouseReceiptNumberList = []
      this.tableData.forEach(item => {
        warehouseReceiptNumberList.push(item.warehouseReceiptNumber)
      })
      let params = {
        process_id: '00000208',
        execution_type: 'get', // get 代表读请求 获取数据,  set 代表写请求 编辑等操作
        nextProcessNode: '003', // 列表接口的  button.NodeId_3字段的值
        nodeInfo: {
          contractId: this.contractId, // 合同id
          warehouseId: this.infoForm.warehouseName
        }
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params).then((res) => {
        this.gridTableData = res.data.content
        this.$nextTick(() => {
          this.gridTableData.forEach(item => {
            if (warehouseReceiptNumberList.indexOf(item.warehouseReceiptNumber) > -1) {
              this.$refs.multipleTable.toggleRowSelection(item, true)
            }
          })
        })
      }).catch((err) => {
        console.log(err)
      })
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
      this.getWeightTonTotel = 0
      let arr = val.map(item => item.availableWeightTon)
      this.getWeightTonTotel = utils.hack.computeTotel(arr)
    },
    delItem (data) {
      let arr = this.tableData.filter(item => {
        return item.warehouseReceiptId != data.warehouseReceiptId
      })
      this.tableData = arr
    },
    sendCode () {
      if (!this.show) {
        return
      }
      let params = {
        process_id: '00000208',
        execution_type: 'get', // get 代表读请求 获取数据,  set 代表写请求 编辑等操作
        nextProcessNode: '006', // 列表接口的  button.NodeId_4字段的值
        nodeInfo: {}
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
        .then(res => {
          if (res.succeed == 0) {
            this.$message({
              message: res.errorMsg,
              type: 'success'
            })
            this.timeCount = 60
            if (!this.timer) {
              this.show = false
              this.timer = setInterval(() => {
                if (this.timeCount > 0) {
                  this.timeCount--
                } else {
                  this.show = true
                  clearInterval(this.timer)
                  this.timer = null
                }
              }, 1000)
            }
          } else {
            this.$message.error(`${res.errorMsg}`)
            this.show = true
            this.timeCount = 60
            if (!this.timer) {
              this.show = false
              this.timer = setInterval(() => {
                if (this.timeCount > 0) {
                  this.timeCount--
                } else {
                  this.show = true
                  clearInterval(this.timer)
                  this.timer = null
                }
              }, 1000)
            }
            // clearInterval(this.timer);
            // this.timer = null;
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    confirm () {
      this.dialogTableVisible = false
      this.tableData = this.multipleSelection
    },
    cancel () {
      this.getWeightTonTotel = 0
      this.dialogTableVisible = false
    },
    setToPwd () {
      this.$router.push({
        path: '/userInfo/transactionPwd',
        query: { tag: 'billTransfer' }
      })
    },
    onSubmit () {
      if (this.tableData.length == 0) {
        this.$alert('仓单列表不能为空')
        return
      }
      let params = {
        process_id: '00000208',
        execution_type: 'set', // get 代表读请求 获取数据,  set 代表写请求 编辑等操作
        nextProcessNode: '003', // 列表接口的  button.NodeId_3字段的值
        nodeInfo: {
          contractId: this.contractId, // 合同id
          mobilePhoneVerificationCode: this.form.mobilePhoneVerificationCode, // 收到的短信验证码
          transactionPassword: this.form.transactionPassword, // 当前用户的交易密码
          warehouseReceipts: this.tableData
        }
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params).then((res) => {
        if (res.succeed == 0) {
          this.$emit('closeBillTransferManagement', true)
          this.$message({
            message: res.errorMsg,
            type: 'success'
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  border-bottom: 3px solid #1d74e0;
  height: 35px;
  line-height: 27px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  padding-left: 30px;
}
.c-title {
  color: #717071;
  font-size: 14px;
  height: 35px;
  margin: 10px auto;
  line-height: 35px;
  border-bottom: 1px dashed #e3e3e3;
}
.bill-btn {
  background: #c3214a;
  color: #fff;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  width: 120px;
  margin: 15px 0;
  text-align: center;
  cursor: pointer;
}
.submit-btn {
  background: #c3214a;
  color: #fff;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  width: 120px;
  text-align: center;
  cursor: pointer;
}
.code-btn {
  background-color: #2d5171;
  color: #fff;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  width: 100px;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.btn-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .btn {
    color: #fff;
    font-size: 12px;
    height: 28px;
    line-height: 28px;
    width: 100px;
    text-align: center;
    cursor: pointer;
    &.confirm {
      background: #c3214a;
    }
    &.cancel {
      background-color: #2d5171;
      margin-left: 20px;
    }
  }
}

.form-container {
  background: #fffdee;
  padding: 15px;
  border: 1px solid #e8d28c;
  margin-top: 10px;
}
.pwd-txt {
  color: #409eff;
  cursor: pointer;
}
.code-btn {
    ::v-deep .el-input__inner {
    -webkit-text-security:disc!important;
  }
}
</style>
