<template>
  <div>
    <div v-show="!constractDetail && !showBill">
      <div class="title">合同管理</div>
      <div class="form">
        <el-form ref="form" :model="form" label-width="100px" size="small">
          <el-row>
            <el-col style="width: 360px;">
              <el-form-item label="合同编号：">
                <el-input v-model="form.contractNumber" style="width: 240px;"></el-input>
              </el-form-item>
              <el-form-item label="合同日期：" prop="name">
                <el-date-picker v-model="form.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 240px;">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col style="width: 360px;">
              <el-form-item label="买方名称：">
                <el-input v-model="form.buyerName" style="width: 240px;"></el-input>
              </el-form-item>
              <el-form-item label="显示作废合同：">
                <input type="checkbox" v-model="form.displayVoidContract" />
              </el-form-item>
            </el-col>
            <el-col style="width: 360px;">
              <el-form-item label="合同状态：">
                <el-select v-model="form.contractStatus" placeholder="请选择" style="width: 240px;">
                  <el-option v-for="item in options" :key="item.code" :label="item.value" :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <div style="display: flex;">
                  <div class="btn" @click="search">搜索</div>
                  <div class="btn" @click="reset">重置</div>
                  <div class="btn" @click="exportExcel">导出Excel</div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="block">
        <div class="label" @click="changeToBuyers">查看所有采购合同</div>
        <div class="info">
          <div>
            总合同重量: <span style="color:red;">{{ totalContractWeight }}</span>吨
          </div>
          <div>
            总合同金额：<span style="color:red;">{{ totalContractAmount }}</span>元
          </div>
        </div>
      </div>

      <div class="table">
        <table>
          <tr>
            <th v-for="(item, index) in tableHeader" :key="index">
              {{ item.label }}
            </th>
          </tr>
          <tbody v-if="tableData.length == 0">
            <tr>
              <td colspan="9" style="text-align: center;">暂无数据</td>
            </tr>
          </tbody>
          <tbody v-else v-for="(item, index) in tableData" :key="index">
            <tr>
              <td style="width: 180px;">{{ item.buyerName }}</td>
              <td style="width: 80px;">{{ item.traderName }}</td>
              <td @click="
                  gotoDetail(
                    item.contractId,
                    item.contractStatus,
                    item.buyerId,
                    item.sellerId,
                    item.buyerName,
                    item.sellerName,
                    item.contractNumber,
                    item.warehouseName
                  )
                " style="width: 80px;color: #3366cc;cursor:pointer;">
                {{ item.contractNumber }}
              </td>
              <td style="width: 80px;color:red; font-weight: 700;font-size:16px;">
                ￥{{ item.contractAmount }}
              </td>
              <td style="width: 180px;">{{ item.commodityName }}</td>
              <td style="width: 80px;">{{ item.contractWeight }}</td>
              <td style="width: 150px;">{{ item.warehouseName }}</td>
              <td style="width: 180px;">
                {{ getContractStatuses(item.contractStatus) }}
              </td>
              <td style="width: 150px;">
                <el-button type="text" @click="
                    gotoDetail(
                      item.contractId,
                      item.contractStatus,
                      item.buyerId,
                      item.sellerId,
                      item.buyerName,
                      item.sellerName,
                      item.contractNumber,
                      item.warehouseName
                    )
                  ">详情</el-button>
                <el-button type="text" @click="
                    gotoDetail(
                      item.contractId,
                      item.contractStatus,
                      item.buyerId,
                      item.sellerId,
                      item.buyerName,
                      item.sellerName,
                      item.contractNumber,
                      item.warehouseName
                    )
                  " v-if="(item.contractStatus == '100' || item.contractStatus == '201') && item.sellerId == userInfo.organizationId[0]">过户</el-button>
                <el-button type="text" @click="
                    gotoDetail(
                      item.contractId,
                      item.contractStatus,
                      item.buyerId,
                      item.sellerId,
                      item.buyerName,
                      item.sellerName,
                      item.contractNumber,
                      item.warehouseName
                    )
                  " v-if="item.contractStatus == '91'">{{
                    userInfo.organizationId[0] == item.buyerId
                      ? "确认"
                      : userInfo.organizationId[0] == item.sellerId
                      ? "撤销"
                      : ""
                  }}</el-button>
                <el-button type="text" @click="
                    gotoDetail(
                      item.contractId,
                      item.contractStatus,
                      item.buyerId,
                      item.sellerId,
                      item.buyerName,
                      item.sellerName,
                      item.contractNumber,
                      item.warehouseName
                    )
                  " v-if="item.contractStatus == '90'">{{
                    userInfo.organizationId[0] == item.sellerId
                      ? "确认"
                      : userInfo.organizationId[0] == item.buyerId
                      ? "撤销"
                      : ""
                  }}</el-button>
                <el-button type="text" @click="
                    gotoDetail(
                      item.contractId,
                      item.contractStatus,
                      item.buyerId,
                      item.sellerId,
                      item.buyerName,
                      item.sellerName,
                      item.contractNumber,
                      item.warehouseName
                    )
                  " v-if="item.contractStatus == '99'">上传合同</el-button>
                <el-button type="text" @click="
                    gotoDetail(
                      item.contractId,
                      item.contractStatus,
                      item.buyerId,
                      item.sellerId,
                      item.buyerName,
                      item.sellerName,
                      item.contractNumber,
                      item.warehouseName
                    )
                  " v-if="
                    item.contractStatus == '100' &&
                      userInfo.organizationId[0] == item.buyerId
                  ">支付</el-button>
                <el-button type="text" @click="
                    gotoDetail(
                      item.contractId,
                      item.contractStatus,
                      item.buyerId,
                      item.sellerId,
                      item.buyerName,
                      item.sellerName,
                      item.contractNumber,
                      item.warehouseName
                    )
                  " v-if="
                    item.contractStatus == '201' &&
                      userInfo.organizationId[0] == item.buyerId
                  ">收货</el-button>
              </td>
            </tr>
            <tr>
              <td colspan="8"></td>
              <td colspan="1" style="font-weight:bold;">
                {{ item.contractTime }}
              </td>
            </tr>
          </tbody>
        </table>
        <el-pagination background layout="total, prev, pager, next" :total="total" @current-change="handleCurrentChange" style="margin-top: 10px;"></el-pagination>
      </div>

      <div class="block">
        <div></div>
        <div class="info">
          <div>
            总合同重量: <span style="color:red;">{{ totalContractWeight }}</span>吨
          </div>
          <div>
            总合同金额：<span style="color:red;">{{ totalContractAmount }}</span>元
          </div>
        </div>
      </div>
    </div>
    <div class="contract-detail" v-show="constractDetail && !showBill">
      <img :src="srcImg" />
      <div class="title">合同详细</div>
      <h2 class="c-title">合同编号：{{ contract.contractNumber }}</h2>
      <div class="detail-form">
        <el-row>
          <el-col :span="10">
            <div>
              <span class="label">买方公司：</span>
              <span>{{ contract.buyerName }}</span>
            </div>
            <div>
              <span class="label">买方交易员：</span>
              <span>{{ contract.buyerTraderName }}</span>
            </div>
            <div>
              <span class="label">订单类型：</span>
              <span>{{ getOrderTypes(contract.orderType) }}</span>
            </div>
            <div>
              <span class="label">支付类型：</span>
              <span>{{ contract.payType }}</span>
            </div>
            <div>
              <span class="label">合同重量：</span>
              <span style="color:#ff6600">{{ contract.contractWeight }}</span>(吨)
            </div>
            <div>
              <span class="label">实际重量：</span>
              <span style="color:#ff6600">{{ contract.actualWeight }}</span>(吨)
            </div>
            <div>
              <span class="label">合同日期：</span>
              <span>{{ contract.contractDate }}</span>
            </div>
            <!-- <div v-show="pageTag !== 'offlineSelling' && contractStatusId !== '91'">
              <span class="label">提单：</span>
              <span>{{contract.offlineLadingBill}}</span>
            </div> -->
            <div v-show="
                contract.contractType == '1' || contract.contractType == '3'
              ">
              <span class="label">水单：</span>
              <span>
                <img src="../../../assets/imgs/hd40.png" style="vertical-align: bottom;" @click="showFile('credentials')" />
              </span>
            </div>
            <div v-show="
                contract.contractType == '1' || contract.contractType == '3'
              ">
              <span class="label">合同附件：</span>
              <span>
                <img src="../../../assets/imgs/hd40.png" style="vertical-align: bottom;" @click="showFile('file')" />
              </span>
            </div>
            <div>
              <span class="label">备注：</span>
              <span>{{ contract.remark }}</span>
              <span style="color: blue; cursor:pointer;" @click="addRemark">{{
                isEdit ? "修改备注" : "添加备注"
              }}</span>
            </div>
          </el-col>
          <el-col :span="10">
            <div>
              <span class="label">合同状态：</span>
              <span style="color: red;">{{
                getContractStatuses(contract.contractStatus)
              }}</span>
            </div>
            <div>
              <span class="label">卖方交易员：</span>
              <span>{{ contract.sellerTraderName }}</span>
            </div>
            <div>
              <span class="label">合同类型：</span>
              <span>{{ getContractTypes(contract.contractType) }}</span>
            </div>
            <div>
              <span class="label">合同金额：</span>
              <span style="color:#ff6600">{{ contract.contractAmount }}</span>(元)
            </div>
            <div>
              <span class="label">已收金额：</span>
              <span style="color:#ff6600">{{ contract.amountReceived }}</span>(元)
            </div>
            <div>
              <span class="label">实际金额：</span>
              <span style="color:#ff6600">{{ contract.actualAmount }}</span>(吨)
            </div>
            <div>
              <span class="label">交货日期：</span>
              <span>{{ contract.deliveryDate }}</span>
            </div>
            <div v-show="
                contract.contractType == '1' || contract.contractType == '3'
              ">
              <span class="label">提单：</span>
              <span>
                <img src="../../../assets/imgs/hd40.png" style="vertical-align: bottom;" @click="showFile('bill')" />
              </span>
            </div>
            <!-- <div v-show="pageTag == 'offlineSelling' || contractStatusId == '91'">
              <span class="label">应收账款转让确认书：</span>
              <span style="color: red;">未上传</span>
            </div>
            <div v-show="pageTag !== 'offlineSelling' && contractStatusId !== '91'">
              <span class="label">支付凭证：</span>
              <span>{{contract.offlineWaterBillOrPaymentVoucher}}</span>
            </div> -->
          </el-col>
        </el-row>
      </div>
      <h2 class="c-title">合同明细（<span>{{getWeightTonTotel(transferOrderStatusDesc)}}</span>）</h2>
      <el-table :data="detailTableData" :header-cell-style="{
          'text-align': 'center',
          'font-weight': 'normal',
          'font-size': '12px',
          color: '#333'
        }" :cell-style="{
          'text-align': 'center',
          'font-weight': 'normal',
          'font-size': '12px',
          color: '#333'
        }" border style="width: 100%" size="mini">
        <el-table-column prop="commodityName" label="品名" width="480">
        </el-table-column>
        <el-table-column prop="productorName" label="品牌"> </el-table-column>
        <el-table-column prop="weight" label="合同重量"> </el-table-column>
        <el-table-column prop="weightUnit" label="重量单位"> </el-table-column>
        <el-table-column prop="price" label="合同单价（元）"> </el-table-column>
        <el-table-column prop="amount" label="合同金额(元)"> </el-table-column>
        <el-table-column prop="deliveryWarehouse" label="交货仓库">
        </el-table-column>
      </el-table>

      <el-dialog title="添加备注" :visible.sync="dialogFormVisible" width="20%">
        <span slot="title">{{ isEdit ? "修改备注" : "添加备注" }}</span>
        <el-form :model="remarkForm" style="width: 280px;">
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" v-model="remarkForm.remark"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="mini" @click="dialogFormVisible = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="submitRemark">确 定</el-button>
        </div>
      </el-dialog>

      <div class="btn-group">
        <div class="btn sec" v-if="contractStatusId == '99'" @click="showContractUpload('upload')">上传合同</div>
        <div class="btn sec" v-if="contractStatusId == '100' && userInfo.organizationId[0] == buyerId" @click="offlinePay">线下支付</div>
        <div class="btn sec" v-if="(contractStatusId == '100' || contractStatusId == '201') && userInfo.organizationId[0] == buyerId" @click="confirmGoods">确认收货</div>
        <div class="btn sec" v-if="contractStatusId == '100' || contractStatusId == '201'" @click="showContractUpload('againUpload')">重传合同</div>
        <div class="btn sec" v-if="contractStatusId == '90'" @click="confirm">{{userInfo.organizationId[0] == sellerId ? "确认合同": userInfo.organizationId[0] == buyerId? "撤销": "撤销"}}</div>
        <div class="btn sec" v-if="contractStatusId == '91'" @click="confirm">{{userInfo.organizationId[0] == buyerId? "确认合同": userInfo.organizationId[0] == sellerId? "撤销": "撤销"}}</div>
        <div class="btn sec" v-if="(contractStatusId == '100' || contractStatusId == '201') && sellerId == userInfo.organizationId[0]" @click="transferOwnership">过户</div>
        <div class="btn" @click="goback">返&nbsp;&nbsp;回</div>
      </div>
    </div>

    <el-dialog title="附件" :visible.sync="fileVisible" width="30%">
      <span v-if="urlList.length == 0">暂无附件</span>
      <div v-for="(item, index) in urlList" :key="index" @click="showImg(item.url, item.name)">
        <span style="cursor:pointer;color: rgb(51, 102, 204);">{{
          item.name
        }}</span>
      </div>
    </el-dialog>

    <el-dialog title="双章合同" :visible.sync="contractVisible" width="30%" class="contractDialog">
      <el-table :data="contractTableData" border style="width: 100%">
        <el-table-column prop="name" label="名称" width="120">
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <div style="display:flex;align-items: baseline;">
              <el-upload accept=".pdf, .jpg, .png, .jpeg" v-show="scope.row.showUpload" class="upload-demo"
                          :multiple="false"
                          :limit="limit"  :ref="'upload' + scope.row.tag" :data="{ tag: scope.row.tag }" action :http-request="uploadContract">
                <el-button type="text" >上传</el-button>
              </el-upload>
              <el-button type="text" @click="check(scope.row.tag)" :class="{'see-btn': scope.row.showUpload}" v-show="scope.row.file">查看</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="contractVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="confirmContract" size="mini">确 定</el-button>
      </div>
    </el-dialog>

    <bill-transfer-management v-if="showBill" @closeBillTransferManagement="closeBillTransferManagement" :contractId="contractId" :sellerName="sellerName" :buyerName="buyerName" :contractNumber="contractNumber" :warehouseName="warehouseName"></bill-transfer-management>
  </div>
</template>

<script>
import BillTransferManagement from '../../components/billTransferManagement'
import axios from 'axios'
import utils from '@/utils'
import {
  getContractStatuses,
  getContractTypes,
  getOrderTypes
} from '../../../utils/formatTime'
const http = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json'
  }
})
export default {
  components: { BillTransferManagement },
  data () {
    return {
      form: {
        contractNumber: '',
        date: [],
        buyerName: '',
        displayVoidContract: false,
        contractStatus: ''
      },
      tableData: [],
      tableHeader: [],

      constractDetail: false,

      dialogFormVisible: false,

      detailTableData: [],

      remark: '',

      formLabelWidth: '60px',
      remarkForm: {
        remark: ''
      },
      isEdit: false,

      options: [],

      contract: {},

      pageTag: '',
      contractStatusId: '',
      contractId: '',

      urlList: [],
      fileUrlList: [],
      billUrlList: [],
      credentialsUrlList: [],

      fileVisible: false,
      url: '',
      srcImg: '',

      totalContractAmount: 0,
      totalContractWeight: 0,

      total: 0,

      currentPage: 0,
      pageSize: 10,

      buyerId: '',
      sellerId: '',

      contractTableData: [
        { name: '买方合同文件', file: '', tag: 'buyer', showUpload: false },
        { name: '卖方合同文件', file: '', tag: 'seller', showUpload: false },
        { name: '双章合同文件', file: '', tag: 'both', showUpload: true }
      ],
      contractVisible: false,

      buyerContractFile: '',
      sellerContractFile: '',
      twoChapterContractFile: '',

      uploadStatus: '',

      showBill: false,

      sellerName: '',
      buyerName: '',
      contractNumber: '',
      warehouseName: '',

      limit: 1,
      transferWeight: '',
      transferOrderStatusDesc: ''
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.UserInfo
    }
  },
  created () {
    this.getTypes()
    this.search()
    this.constractDetail = this.$route.query.tag == 'offlineSelling'
    this.pageTag =
      this.$route.query.tag == 'offlineSelling' ? 'offlineSelling' : ''
    // this.detailTableData = JSON.parse(sessionStorage.getItem('contractDetails'));
    // let detailInfo = JSON.parse(sessionStorage.getItem('contract'));
    // this.contract = detailInfo;

    if (this.pageTag == 'offlineSelling') {
      this.gotoDetail(this.$route.query.contractId)
    }
  },
  methods: {
    getContractStatuses,
    getContractTypes,
    getOrderTypes,
    getTypes () {
      let params = {}
      this.$post('355cc1ed-6673-a8fe-737a-7b915fca6ff8', params)
        .then(res => {
          const { data } = res
          this.options = data.contractStatuses
          this.$store.commit('setContractStatuses', this.options)
          this.$store.commit('setContractTypes', data.contractTypes)
          this.$store.commit('setOrderTypes', data.orderTypes)
        })
        .catch(err => { })
    },
    getWeightTonTotel (transferOrderStatusDesc) {
      if (transferOrderStatusDesc === '已过户') {
        return '已过户' + this.transferWeight / 1000 + '吨'
      } else {
        return transferOrderStatusDesc
      }
    },
    search () {
      let params = {
        process_id: '00000208', // 流程ID
        // 传参  查询则是查询条件  可传可不传等
        nodeInfo: {
          size: this.pageSize, // 分页页容量（从0开始，-1代表查全部）
          page: this.currentPage, // 分页页码（从0开始）
          contractNumber: this.form.contractNumber, // 合同编号
          buyerName: this.form.buyerName, // 买方名称
          sellerName: '', // 卖方名称
          contractStatus: this.form.contractStatus, // 合同状态
          contractBeginDate:
            this.form.date.length == 0 ? '' : this.form.date[0], // 合同开始时间
          contractEndDate: this.form.date.length == 0 ? '' : this.form.date[1], // 合同截止时间
          displayVoidContract: !this.form.displayVoidContract ? 0 : 1, // 是否显示作废合同（0-不显示/1-显示）：
          displaySellOrBuy: 'S'
        }
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
        .then(res => {
          const { data } = res
          this.tableHeader = data.title
          this.tableData = data.content
          this.totalContractAmount = data.totalContractAmount
          this.totalContractWeight = data.totalContractWeight
          this.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    gotoDetail (id, status, buyerId, sellerId, buyerName, sellerName, contractNumber, warehouseName) {
      this.contractStatusId = status
      this.contractId = id
      this.buyerId = buyerId
      this.sellerId = sellerId
      this.constractDetail = true
      this.pageTag =
        this.$route.query.tag == 'offlineSelling' ? 'offlineSelling' : ''

      this.buyerName = buyerName
      this.sellerName = sellerName
      this.contractNumber = contractNumber
      this.warehouseName = warehouseName

      // 获取详情页信息
      this.getDetailInfo()
    },
    getDetailInfo () {
      let params = {
        process_id: '00000208',
        execution_type: 'get', // get 代表读请求 获取数据,  set	代表写请求 编辑等操作
        nextProcessNode: '001', // 上面接口的  operate.node_id字段的值
        nodeInfo: {
          contractId: this.contractId // 合同id
        }
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
        .then(res => {
          const { data } = res
          this.contract = data.contract

          this.contractStatusId = this.contract.contractStatus

          this.detailTableData = data.contract.details

          this.contractTableData.forEach(item => {
            if (item.tag == 'buyer') {
              item.file = this.contract.buyerContractFile
              item.showUpload = this.buyerId == this.userInfo.organizationId[0]
            } else if (item.tag == 'seller') {
              item.file = this.contract.sellerContractFile
              item.showUpload = this.sellerId == this.userInfo.organizationId[0]
            } else if (item.tag == 'both') {
              item.file = this.contract.twoChapterContractFile
            }
          })
          this.buyerContractFile = this.contract.buyerContractFile
          this.sellerContractFile = this.contract.sellerContractFile
          this.twoChapterContractFile = this.contract.twoChapterContractFile

          // 过户状态
          this.transferOrderStatusDesc = this.contract.transferOrderStatusDesc
          this.transferWeight = this.contract.transferWeight || ''
          this.fileUrlList = []
          this.billUrlList = []
          this.credentialsUrlList = []

          let offlineContractAttachment = JSON.parse(
            data.contract.offlineContractAttachment
          )
          offlineContractAttachment.forEach(item => {
            // 转图片地址
            this.getImg(item, 'file')
          })

          let offlineLadingBill = JSON.parse(data.contract.offlineLadingBill)
          offlineLadingBill.forEach(item => {
            // 转图片地址
            this.getImg(item, 'bill')
          })

          let offlineWaterBillOrPaymentVoucher = JSON.parse(
            data.contract.offlineWaterBillOrPaymentVoucher
          )
          offlineWaterBillOrPaymentVoucher.forEach(item => {
            // 转图片地址
            this.getImg(item, 'credentials')
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    check (tag) {
      if (tag == 'buyer') {
        this.getImg(this.buyerContractFile, tag)
      } else if (tag == 'seller') {
        this.getImg(this.sellerContractFile, tag)
      } else if (tag == 'both') {
        this.getImg(this.twoChapterContractFile, tag)
      }
    },
    getImg (rowData, tag) {
      http.get('/file/getFilePath?fileId=' + rowData).then(res => {
        const { data } = res.data
        if (tag == 'file') {
          this.fileUrlList.push({
            name: data?.['common_file@file_name'],
            url: data?.['common_file@path']
          })
        } else if (tag == 'bill') {
          this.billUrlList.push({
            name: data?.['common_file@file_name'],
            url: data?.['common_file@path']
          })
        } else if (tag == 'credentials') {
          this.credentialsUrlList.push({
            name: data?.['common_file@file_name'],
            url: data?.['common_file@path']
          })
        } else if (tag == 'buyer' || tag == 'seller' || tag == 'both') {
          this.showImg(data?.['common_file@path'])
        }
      })
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (rowIndex % 2 === 1) {
        if (columnIndex === 0) {
          return [1, 10]
        } else if (columnIndex === 1) {
          return [0, 0]
        }
      }
    },
    addRemark () {
      this.dialogFormVisible = true
    },
    submitRemark () {
      this.dialogFormVisible = false
      this.contract.remark = this.remarkForm.remark
      this.isEdit = true
      // 保存备注
      let params = {
        process_id: '00000208',
        execution_type: 'set', // get 代表读请求 获取数据,  set  代表写请求 编辑等操作
        nextProcessNode: '007', // 上面接口的  button.NodeId_5字段的值
        nodeInfo: {
          contractId: this.contractId, // 合同id
          remark: this.remarkForm.remark // 合同备注
        }
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params).then((res) => {
        if (res.succeed == 0) {
          this.$message({
            message: `${res.errorMsg}`,
            type: 'success'
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    goback () {
      if (this.pageTag == 'offlineSelling') {
        this.$router.push('/contractManagement/contractManagement')
        this.constractDetail = false
      } else {
        this.constractDetail = false
      }
      this.search()
    },
    changeToBuyers () {
      this.$router.push('/bContractManagement/bContractManagement')
    },
    reset () {
      this.form = {
        contractNumber: '',
        date: [],
        buyerName: '',
        displayVoidContract: false,
        contractStatus: ''
      }
      this.search()
    },
    exportExcel () {
      let params = {
        process_id: '00000208',
        execution_type: 'get', // get 代表读请求 获取数据,  set 代表写请求 编辑等操作
        nextProcessNode: '005', // 上面接口的  button.NodeId_1字段的值
        nodeInfo: {
          size: -1, // 分页页容量（从0开始，-1代表查全部）
          page: 0, // 分页页码（从0开始）
          contractNumber: this.form.contractNumber, // 合同编号
          buyerName: this.form.buyerName, // 买方名称
          sellerName: '', // 卖方名称
          contractStatus: this.form.contractStatus, // 合同状态
          contractBeginDate: this.form.date.length == 0 ? '' : this.form.date[0], // 合同开始时间
          contractEndDate: this.form.date.length == 0 ? '' : this.form.date[1], // 合同截止时间
          displayVoidContract: !this.form.displayVoidContract ? 0 : 1, // 是否显示作废合同（0-不显示/1-显示）：
          displaySellOrBuy: 'S'
        }
      }
      let p1 = new Promise((resolve, reject) => {
        this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params).then((res) => {
          resolve(res.data.id)
        }).catch((err) => {
          console.log(err)
        })
      })
      p1.then(res => {
        http.get('/file/getFilePath?fileId=' + res).then((result) => {
          const { data } = result.data
          let url = data['common_file@path']
          window.open(`/downfile${url}`, '_blank')
        }).catch((err) => {
          console.log(err)
        })
      })
    },
    showFile (tag) {
      this.fileVisible = true
      if (tag == 'credentials') {
        this.urlList = this.credentialsUrlList
      } else if (tag == 'bill') {
        this.urlList = this.billUrlList
      } else if (tag == 'file') {
        this.urlList = this.fileUrlList
      }
    },
    showImg (url, name) {
      window.open(`/downfile${url}`, '_blank')
    },
    cancel () {
      this.updateContractStatus('cancel')
    },
    confirm () {
      if (this.contractStatusId == '90') {
        if (this.userInfo.organizationId[0] == this.buyerId) {
          this.updateContractStatus('cancel')
        } else if (this.userInfo.organizationId[0] == this.sellerId) {
          this.updateContractStatus('confirm')
        } else {
          this.updateContractStatus('cancel')
        }
      } else if (this.contractStatusId == '91') {
        if (this.userInfo.organizationId[0] == this.buyerId) {
          this.updateContractStatus('confirm')
        } else if (this.userInfo.organizationId[0] == this.sellerId) {
          this.updateContractStatus('cancel')
        } else {
          this.updateContractStatus('cancel')
        }
      }
    },
    offlinePay () {
      this.updateContractStatus('offlinePay')
    },
    confirmGoods () {
      this.updateContractStatus('confirmGoods')
    },
    updateContractStatus (tag) {
      let contractStatus = ''
      if (tag == 'cancel') {
        contractStatus =
          this.userInfo.organizationId[0] == this.buyerId
            ? '83'
            : this.userInfo.organizationId[0] == this.sellerId
              ? '82'
              : '82'
      } else if (tag == 'confirm') {
        contractStatus =
          this.contract.contractType === '1' || this.contract.contractType === '3'
            ? '400'
            : '99'
      } else if (tag == 'upload') {
        contractStatus = '100'
      } else if (tag == 'offlinePay') {
        contractStatus = '201'
      } else if (tag == 'confirmGoods') {
        contractStatus = '400'
      }
      let params = {
        process_id: '00000208',
        execution_type: 'set', // get 代表读请求 获取数据,  set代表写请求 编辑等操作
        nextProcessNode: '001', // 上面接口的  operate.node_id字段的值
        nodeInfo: {
          contractId: this.contractId, // 合同id
          contractStatus: contractStatus // 合同状态
        }
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
        .then(res => {
          if (res.succeed == 0) {
            this.goback()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleCurrentChange (page) {
      this.currentPage = page - 1
      this.search()
    },
    showContractUpload (tag) {
      this.contractVisible = true
      this.$nextTick(() => {
        this.$refs.uploadboth.clearFiles()
        this.$refs.uploadbuyer.clearFiles()
        this.$refs.uploadseller.clearFiles()
      })

      this.uploadStatus = tag
    },
    uploadContract (param) {
      let p = new Promise((resolve, reject) => {
        // 上传 下载key获取
        let params = {
          process_id: '00000208',
          nextProcessNode: '004',
          addr: 'getDownloadKey', // 获取列表 接口返回的url.downloadKeyUrl.addr 的值
          nodeInfo: {}
        }
        this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })

      p.then(res => {
        const fd = new FormData()
        fd.append('file', param.file)
        fd.append('key', res)
        fd.append('systemId', this.userInfo.id)
        fd.append('orgId', this.userInfo.organizationId[0])

        http.post('/file/upload', fd).then(res => {
          const { data } = res.data
          if (param.data.tag == 'buyer') {
            this.buyerContractFile = data
          } else if (param.data.tag == 'seller') {
            this.sellerContractFile = data
          } else if (param.data.tag == 'both') {
            this.twoChapterContractFile = data
          }
        })
      })
    },
    confirmContract () {
      if (
        this.buyerContractFile == '' &&
        this.sellerContractFile == '' &&
        this.twoChapterContractFile == ''
      ) {
        this.$alert('请上传合同文件')
        return
      }
      let params = {
        process_id: '00000208',
        execution_type: 'set',
        nextProcessNode: '002',
        nodeInfo: {
          contractId: this.contractId,
          buyerContractFile: this.buyerContractFile,
          sellerContractFile: this.sellerContractFile,
          twoChapterContractFile: this.twoChapterContractFile
        }
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
        .then(res => {
          if (res.succeed == 0) {
            this.$message({
              message: res.errorMsg,
              type: 'success'
            })
            this.contractVisible = false

            this.contractTableData.forEach(item => {
              if (item.tag == 'buyer') {
                item.file = this.buyerContractFile
                item.showUpload = this.buyerId == this.userInfo.organizationId[0]
              } else if (item.tag == 'seller') {
                item.file = this.sellerContractFile
                item.showUpload = this.sellerId == this.userInfo.organizationId[0]
              } else if (item.tag == 'both') {
                item.file = this.twoChapterContractFile
              }
            })
            // if (this.uploadStatus == 'upload' && this.twoChapterContractFile) {
            //   this.updateContractStatus(this.uploadStatus);
            // }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    transferOwnership () {
      this.showBill = true
    },
    closeBillTransferManagement () {
      this.showBill = false
      this.getDetailInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  border-bottom: 3px solid #1d74e0;
  height: 35px;
  line-height: 27px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  padding-left: 30px;
}
.form {
  border: #e5e5e5 1px solid;
  padding: 5px;
  margin-top: 10px;
  .btn {
    background: #2d5171;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #ffffff;
    padding: 0 18px;
    margin-right: 3px;
    cursor: pointer;
  }
}
.block {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 10px 50px 10px 10px;
  margin-top: 30px;
  .label {
    color: #3366cc;
    font-size: 15px;
    cursor: pointer;
  }
  .info {
    font-size: 12px;
    display: flex;
    div {
      margin-right: 30px;
    }
  }
}
.table {
  margin-top: 10px;
}
table,
table tr th,
table tr td {
  border: 1px solid #dddddd;
  color: #333;
  font-size: 12px;
}
table tr th {
  text-align: center;
  line-height: 32px;
}
table {
  border-collapse: collapse;
  width: 100%;
}
table td {
  line-height: 32px;
  padding: 0 15px;
}
.contract-detail {
  .c-title {
    color: #717071;
    font-size: 14px;
    height: 35px;
    margin: 10px auto;
    line-height: 35px;
    border-bottom: 1px dashed #e3e3e3;
  }
  .detail-form {
    padding: 20px 40px;
    div {
      line-height: 30px;
      font-size: 12px;
      color: #000;
    }
    .label {
      display: inline-block;
      width: 150px;
      text-align: right;
    }
  }

  .btn-group {
    display: flex;
    color: #fff;
    width: 500px;
    margin: 20px auto;
    .btn {
      background: #2d5171;
      width: 120px;
      margin-right: 10px;
      text-align: center;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      cursor: pointer;
      &.sec {
        background: #c3214a;
      }
    }
  }
}
.see-btn {
  position: absolute;
  left: 70px;
}
.contractDialog{
  ::v-deep .el-upload-list{
    width: 200px;
  }
  ::v-deep .el-upload-list__item-name{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 90%;
  }
}
</style>
